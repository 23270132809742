import React, { useState, memo, useEffect } from "react";
// Chakra imports
import {
  Input,
  Flex,
  Text,
  InputGroup,
  Box,
  Icon,
  HStack,
  InputRightElement,
  Avatar,
} from "@chakra-ui/react";
import { BsEmojiSmile } from "react-icons/bs";
import { RxDoubleArrowUp } from "react-icons/rx";
import Card from "components/card/Card";
import MainMenu from "components/menu/MainMenu";
import avatar from "assets/img/global/avatar.png";
import { useDispatch, useSelector } from "react-redux";
import { app, db, auth } from "firebaseConfig";
import { environment, oneSignalKey, oneSignalRestApiKey } from "utilities/constants";
import axios from "axios";
import OneSignal from "react-onesignal";

var { Launcher } = require("react-chat-window");

interface Props {
  state: any;
}

function Chat(props: any) {
  const {
    registerPlayers,
    challonge_Tournament_Id,
    tournament_Name,
    coverPath,
  } = props.state;
  const textColorSecondary_2 = "gray.300";
  const { userId, userName, logo } = useSelector((state: any) => state.Auth);
  const [messages, setMessages] = useState([]);
  const [ipAddress, setipAddress] = useState("");
  const [latitude, setlatitude] = useState(0);
  const [longitude, setlongitudes] = useState(0);

  const [playerIds, setPlayerIds] = useState([]);
  const [text, setText] = useState("");
  useEffect(() => {
    console.log({ userId });
    if (challonge_Tournament_Id) {
      //
      getChatRoomInfo();
      getMessages();
      getPlayerIds();
      getIp();
    }
  }, [challonge_Tournament_Id]);
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setlatitude(position.coords.latitude);
        setlongitudes(position.coords.longitude);

        console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);
  const getIp = async () => {
    const res = await axios.get("https://api.ipify.org?format=json");
    const ip = res.data.ip;
    setipAddress(ip);
  };
  const initChat = async (lastMessage: string, message?: any) => {
    let arr = [...registerPlayers];
    arr.push({
      playerId: userId.toString(),
      playerName: userName,
      playerProfilePicPath: logo ?? "",
    });
    let users = arr.map((x: any) => x.playerId);
    let usersInfo = arr.map((x: any) => {
      return {
        count: 0,
        hasDelivered: false,
        hasExited: false,
        id: x.playerId,
        image: x.playerProfilePicPath ?? "",
        isDeleted: false,
        name: x.playerName,
        unRead: false,
      };
    });

    const collectionName = "messages" + environment;
    const messagesCol: any = db
      .collection(collectionName)
      .doc(challonge_Tournament_Id);
    await messagesCol.set({
      chatCategory: "event",
      chatType: "group",
      isDeleted: false,
      groupInfo: {
        image: coverPath ?? "",
        coverImage: coverPath ?? "",
        name: tournament_Name,
        description: "",
        groupAdmins: [userId.toString()],
      },
      platform: "web",
      createdBy: userId.toString(),
      id: challonge_Tournament_Id,
      lastMessage: {
        contentType: "empty",
        fromId: "",
        hasSeen: false,
        message: lastMessage,
        timeStamp: Date.now(),
        toIds: users,
      },
      users,
      usersInfo,
    });

    if (lastMessage === "Event Started") return;

    const messageDocRef = db
      .collection(collectionName)
      .doc(challonge_Tournament_Id)
      .collection(challonge_Tournament_Id)
      .doc(message.id);
    await messageDocRef.set(message);
  };

  const getChatRoomInfo = async () => {
    // const messagesCol: any = doc(db, `messages`, challonge_Tournament_Id);
    const collectionName = "messages" + environment;
    const messagesCol: any = db
      .collection(collectionName)
      .doc(challonge_Tournament_Id);

    const messagesSnapshot = await messagesCol.get();
    if (!messagesSnapshot._exists) {
      initChat("Event Started");
    }
  };

  async function getMessages() {
    const collectionName = "messages" + environment;
    const messageDocRef: any = db
      .collection(collectionName)
      .doc(challonge_Tournament_Id)
      .collection(challonge_Tournament_Id)
      .orderBy("timeStamp");
    messageDocRef.onSnapshot(messageDocRef, (querySnapshot: any) => {
      let arr: any = [];
      querySnapshot.docs.forEach((doc: any) => {
        console.log({ data: doc.data() });

        let obj = {
          author: userId == doc.data().idFrom ? "me" : "them",
          type: "text",
          data: {
            text: doc.data().content,
          },
        };
        arr.push(obj);
      });
      setMessages((prvState) => [...arr]);
    });
  }

  const sendMessage = (text: string) => {
    let permission = OneSignal.Notifications.permission;
    let chatId = Date.now();
    let arr = [...registerPlayers];
    let messageArr = [...messages];
    let users = arr.map((x: any) => x.playerId);
    setText("");
    let obj: any = {
      content: text,
      contentType: "text",
      idFrom: userId.toString(),
      idsTo: [...users, userId.toString()],
      deletedFrom: [],
      timeStamp: Date.now(),
      id: chatId.toString(),
      platform: "web",
      basicInfo: {
        appLanguage: "en_US",
        platform: "web",
        ip: ipAddress,
        deviceId: "",
        deviceName: "",
        isPhysicalDevice: true,
        isDeviceRooted: false,
        isWifiConnected: true,
        username: userName,
        loginType: "form",
        latLong: latitude + "," + longitude,
        appVersion: "",
        createdAt: Date.now(),
        permission: permission ? ["notification"] : [],
      },
    };
    let obj2 = {
      author: "me",
      type: "text",
      data: {
        text: text,
      },
    };
    messageArr.push(obj2);
    setMessages(messageArr);
    initChat(text, obj);
    sendNotification(text);
  };
  const sendNotification = (text: string) => {
    console.log({ playerIds });
    if (playerIds.length === 0) {
      return;
    }
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Basic ${oneSignalRestApiKey}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      app_id: oneSignalKey,
      data: {
        isGroup: true,
        chatId: challonge_Tournament_Id,
        type: "chat",
        groupName: tournament_Name,
      },
      headings: {
        en: "Grid Gamers",
      },
      contents: {
        en: text,
      },
      include_player_ids: playerIds,
    });

    var requestOptions: any = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://onesignal.com/api/v1/notifications", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  };

  const getPlayerIds = async () => {
    if (registerPlayers.length === 0) {
      return;
    }
    const collectionName = "users" + environment;
    const usersDocRef: any = db.collection(collectionName);
    let doc = await usersDocRef.where("id", "in", registerPlayers).get();
    let arr: any = [];
    doc.forEach((data: any) => {
      let playerData = data.data();
      console.log({ playerData });
      if (playerData.playerId.length) {
        arr.push(playerData.playerId);
      }
    });
    console.log({ arr });
    setPlayerIds(arr);
  };

  return (
    <Box>
      <Launcher
        // newMessagesCount={5}
        agentProfile={{
          teamName: tournament_Name,
          imageUrl: coverPath,
        }}
        onMessageWasSent={(e: any) => {
          sendMessage(e.data?.text ?? e.data.emoji);
          console.log({ e });
        }}
        messageList={messages}
        showEmoji
      />
    </Box>
  );
}

export default memo(Chat);
