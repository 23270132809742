import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import React, { useState, memo, useEffect } from "react";

import Card from "components/card/Card";

import {
  Box,
  Flex,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Button,
  Icon,
  Input,
  useColorModeValue,
  Text,
  FormErrorMessage,
  useToast,
  //   Avatar,
  HStack,
} from "@chakra-ui/react";
import avatar from "assets/img/global/avatar.png";
import adminAvatar from "assets/img/global/admin.png";
import userAvatar from "assets/img/global/user.jpg";
// import userAvatar from "assets/img/global/user.png";
import OneSignal from "react-onesignal";

import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  Sidebar,
  Search,
  ConversationList,
  Conversation,
  Avatar,
  ConversationHeader,
  VoiceCallButton,
  VideoCallButton,
  InfoButton,
  MessageSeparator,
  TypingIndicator,
  ExpansionPanel,
  InputToolbox,
  AttachmentButton,
  SendButton,
} from "@chatscope/chat-ui-kit-react";

import Menu from "components/menu/MainMenu";
import { getTicketsList } from "redux/slices/Ticketing";
import { useDispatch, useSelector } from "react-redux";
import { hubAdminUrl, oneSignalRestApiKey } from "utilities/constants";
import { post, get } from "redux/services/api";
import {
  AsyncCreatableSelect,
  AsyncSelect,
  CreatableSelect,
  Select,
} from "chakra-react-select";
import { app, db } from "firebaseConfig";
import moment from "moment";
import { getTicketAdminsList } from "redux/slices/AdminsTableLists";
import { environment } from "utilities/constants";
import axios from "axios";
import ImageModal from "./ImageModal";

const TicketingSystem = () => {
  const buttonColorBrand = useColorModeValue("#19BAEE", "white");
  const buttonColor = useColorModeValue("#F0F4F8", "white");
  const dispatch: any = useDispatch();
  const { loading, TicketsList } = useSelector((state: any) => state.Ticketing);
  const { usersListloading, adminsList, userRolesList, ticketAdminsList } =
    useSelector((state: any) => state.AdminsTableLists);
  const { userId, logo, userName } = useSelector((state: any) => state.Auth);
  console.log({ logo });
  const [search, setsearch] = useState("");
  const [ticketsList, SetTicketsList] = useState(["", ""]);
  const [searchArr, setSearchArr] = useState([]);
  const [playerIds, setPlayerIds] = useState([]);
  const [tickeInfo, SetTickeInfo] = useState(null);
  const [closing, setClosing] = useState(false);
  const [loadingNewMessage, setLoadingNewMessage] = useState(false);
  const [ticketTypesArr, setTicketTypesArr] = useState([]);
  const [selectedticketTypes, setSelectedTicketTypes] = useState({
    answerId: null,
  });
  const [userDropDown, setUserDropDown] = useState([]);
  const [messageInputValue, setMessageInputValue] = useState("");
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const [messages, setMessages] = useState([]);
  const [textType, settextType] = useState("Message");
  const [unsubscribe, setUnsubscribe] = useState(null);
  const [ipAddress, setipAddress] = useState("");
  const [latitude, setlatitude] = useState(0);
  const [longitude, setlongitudes] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  useEffect(() => {
    getIp();

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setlatitude(position.coords.latitude);
        setlongitudes(position.coords.longitude);

        console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);
  const getIp = async () => {
    const res = await axios.get("https://api.ipify.org?format=json");
    const ip = res.data.ip;
    setipAddress(ip);
  };
  useEffect(() => {
    console.log({ logo });
    let payload = {};
    dispatch(getTicketsList(payload));
    getTicketTypes();
  }, []);
  useEffect(() => {
    dispatch(getTicketAdminsList());
  }, []);
  useEffect(() => {
    if (TicketsList) {
      let selectedData = TicketsList.filter(
        (x: any) => x?.id === tickeInfo?.id
      )[0];

      if (selectedData) {
        SetTickeInfo(selectedData);
        getMessages(selectedData);
      }
      SetTicketsList(TicketsList);
    }
  }, [TicketsList]);
  useEffect(() => {
    console.log({ ticketAdminsList });
    if (ticketAdminsList) {
      let arr = ticketAdminsList.map((x: any) => {
        return {
          label: x.userName,
          value: x.userId,
        };
      });
      setUserDropDown(arr);
    }
  }, [ticketAdminsList]);
  const getTicketTypes = async () => {
    let url = `${hubAdminUrl}/api/v1/setting/get-list-of-support-and-feedback?subCatagoryId=14`;
    const response: any = await get(url);
    if (response.data.status === true) {
      console.log({ response: response.data.response.supports });
      setTicketTypesArr(response.data.response.supports[0].supportSubMenuList);
    }
  };
  const closeTicket = async () => {
    setClosing(true);
    let payload = {
      data: {
        ticketNumber: tickeInfo.ticketNo,
        status: "Closed",
      },
      url: `${hubAdminUrl}/api/v1/ticket/status-change`,
    };
    try {
      await post(payload);
      dispatch(getTicketsList({}));
      setClosing(false);
    } catch (err: any) {
      setClosing(false);
      console.log({ errorz: err.message });
    }
  };
  const assignTicket = async (user: any) => {
    setLoadingNewMessage(true);
    setSelectedUsers(user);
    console.log({ user });

    let payload = {
      data: {
        ticketNumber: tickeInfo.ticketNo,
        assignToUserId: user.value,
      },
      url: `${hubAdminUrl}/api/v1/ticket/assign`,
    };
    try {
      await post(payload);
      dispatch(getTicketsList({}));

      setLoadingNewMessage(false);
    } catch (err: any) {
      console.log({ errorz: err.message });
    }
  };
  const SetTickeInfoFunc = (data: any) => {
    // if(unsubscribe){
    //   unsubscribe()
    // }
    if (data?.assignToId != userId) {
      settextType("ticketNote");
    }
    setMessages([]);
    let user = {
      label: data.assignTo,
      value: Number(data.assignToId),
    };
    setSelectedUsers(user);
    SetTickeInfo(data);
    if (data.assignToId) {
      getPlayerIds(data.createdById);
      getMessages(data);
    }
  };
  // const initChat = async (lastMessage: string) => {
  //   let permission = OneSignal.Notifications.permission;
  //   sendNotification(lastMessage);
  //   setMessageInputValue("");
  //   let idArr = [tickeInfo.assignToId, tickeInfo.createdById];
  //   let maxID = Math.max(...idArr);
  //   let minID = Math.min(...idArr);
  //   // let challonge_Tournament_Id = minID + "-" + maxID;
  //   let challonge_Tournament_Id = tickeInfo.ticketNo;

  //   console.log({ challonge_Tournament_Id });
  //   let arr: any = [
  //     {
  //       playerId: tickeInfo.assignToId,
  //       playerName: tickeInfo.assignTo,
  //       playerProfilePicPath: logo??"",
  //     },
  //     {
  //       playerId: tickeInfo.createdById,
  //       playerName: tickeInfo.createdBy,
  //       playerProfilePicPath: tickeInfo.createdByProfilePath,
  //     },
  //   ];

  //   let users = idArr;

  //   let usersInfo = arr.map((x: any) => {
  //     return {
  //       count: 0,
  //       hasDelivered: false,
  //       hasExited: false,
  //       id: x.playerId,
  //       image: x.playerProfilePicPath,
  //       isDeleted: false,
  //       name: x.playerName,
  //       unRead: false,
  //     };
  //   });

  //   // const messagesCol: any = doc(db, `messages`, challonge_Tournament_Id);
  //   const collectionName = "messages" + environment;
  //   const messagesCol: any = db
  //     .collection(collectionName)
  //     .doc(challonge_Tournament_Id);
  //   await messagesCol.set({
  //     chatCategory: "ticket",
  //     chatType: "group",
  //     groupInfo: {
  //       image: "",
  //       coverImage: "",
  //       name: "Grid Customor Service",
  //       description: "",
  //       groupAdmins: [],
  //     },
  //     id: challonge_Tournament_Id,
  //     lastMessage: {
  //       contentType: textType === "ticketNote" ? "ticketNote" : "text",
  //       fromId: "",
  //       hasSeen: false,
  //       message: lastMessage,

  //       timeStamp: Date.now(),
  //       toIds: users,
  //     },
  //     users,
  //     usersInfo,
  //     ticketInfo: {
  //       attachmentPath: tickeInfo.attachmentPath,
  //       createdAt: Date.now(),
  //       description: tickeInfo.ticketDetails,
  //       id: tickeInfo.id,
  //       ticketStatus: tickeInfo.ticketStatus,
  //       ticketType: tickeInfo.ticketTypeName,
  //       ticketTypeId: tickeInfo.ticketTypeId,
  //       ticketAdmin: [
  //         {
  //           assignDateTime: Date.now(),
  //           id: tickeInfo.assignToId,
  //           isActive: true,
  //           name: tickeInfo.assignTo,
  //         },
  //       ],
  //       ticketUser: {
  //         assignDateTime: Date.now(),
  //         id: tickeInfo.createdById,
  //         isActive: true,
  //         name: tickeInfo.createdBy,
  //       },
  //       userReported: {
  //         assignDateTime: Date.now(),
  //         id: tickeInfo.assignToId,
  //         isActive: true,
  //         name: tickeInfo.assignTo,
  //       },
  //     },
  //   });
  //   let chatId=  Date.now().toString();
  //   let message: any = {
  //     content: lastMessage,
  //     id:chatId,
  //     contentType: textType === "ticketNote" ? "ticketNote" : "text",
  //     idFrom: userId.toString(),
  //     idsTo: users,
  //     deletedFrom: [],
  //     timeStamp: Date.now(),
  //     basicInfo: {
  //       appLanguage: "en_US",
  //       platform: "web",
  //       ip: ipAddress,
  //       deviceId: "",
  //       deviceName: "",
  //       isPhysicalDevice: true,
  //       isDeviceRooted: false,
  //       isWifiConnected: true,
  //       username: userName,
  //       loginType: "form",
  //       latLong: latitude + "," + longitude,
  //       appVersion: "",
  //       createdAt: Date.now(),
  //       permission: permission ? ["notification"] : [],
  //     },
  //   };
   

  //   const messageDocRef = db
  //     .collection(collectionName)
  //     .doc(challonge_Tournament_Id)
  //     .collection(challonge_Tournament_Id);
  //   await messageDocRef.doc(chatId).set(message);
  // };

  const initChat = async (lastMessage: string) => {
    let permission = OneSignal.Notifications.permission;
    sendNotification(lastMessage);
    setMessageInputValue("");
    let idArr = [tickeInfo.assignToId, tickeInfo.createdById];
    let maxID = Math.max(...idArr);
    let minID = Math.min(...idArr);
    let challonge_Tournament_Id = tickeInfo.ticketNo;

    console.log({ challonge_Tournament_Id });
    let arr: any = [
      {
        playerId: tickeInfo.assignToId,
        playerName: tickeInfo.assignTo,
        playerProfilePicPath: logo ?? "",
      },
      {
        playerId: tickeInfo.createdById,
        playerName: tickeInfo.createdBy,
        playerProfilePicPath: tickeInfo.createdByProfilePath,
      },
    ];

    let users = idArr;

    let usersInfo = arr.map((x: any) => {
      return {
        count: 0,
        hasDelivered: false,
        hasExited: false,
        id: x.playerId,
        image: x.playerProfilePicPath,
        isDeleted: false,
        name: x.playerName,
        unRead: false,
      };
    });

    const collectionName = "messages" + environment;
    console.log("🚀 ~ initChat ~ collectionName:", collectionName)
    const messagesCol: any = db
      .collection(collectionName)
      .doc(challonge_Tournament_Id);

    // Only update the lastMessage if the contentType is NOT 'ticketNote'
    if (textType !== "ticketNote") {
      await messagesCol.set({
        chatCategory: "ticket",
        chatType: "group",
        groupInfo: {
          image: "https://gridstatic.blob.core.windows.net/stgauthapi/Images/Hub/Profile/81266a8e5fefa7b9.png",
          coverImage: "",
          name: "Grid Customer Service",
          description: "",
          groupAdmins: [],
        },
        id: challonge_Tournament_Id,
        lastMessage: {
          contentType: textType === "ticketNote" ? "ticketNote" : "text",
          fromId: "",
          hasSeen: false,
          message: lastMessage,
          timeStamp: Date.now(),
          toIds: users,
        },
        users,
        usersInfo,
        ticketInfo: {
          attachmentPath: tickeInfo.attachmentPath,
          attachments: tickeInfo.attachmentList,
          createdAt: Date.now(),
          description: tickeInfo.ticketDetails,
          id: tickeInfo.id,
          ticketStatus: tickeInfo.ticketStatus,
          ticketType: tickeInfo.ticketTypeName,
          ticketTypeId: tickeInfo.ticketTypeId,
          ticketAdmin: [
            {
              assignDateTime: Date.now(),
              id: tickeInfo.assignToId,
              isActive: true,
              name: tickeInfo.assignTo,
            },
          ],
          ticketUser: {
            assignDateTime: Date.now(),
            id: tickeInfo.createdById,
            isActive: true,
            name: tickeInfo.createdBy,
          },
          userReported: {
            assignDateTime: Date.now(),
            id: tickeInfo.assignToId,
            isActive: true,
            name: tickeInfo.assignTo,
          },
        },
      });
    }

    let chatId = Date.now().toString();
    let message: any = {
      content: lastMessage,
      id: chatId,
      contentType: textType === "ticketNote" ? "ticketNote" : "text",
      idFrom: userId.toString(),
      idsTo: users,
      deletedFrom: [],
      timeStamp: Date.now(),
      basicInfo: {
        appLanguage: "en_US",
        platform: "web",
        ip: ipAddress,
        deviceId: "",
        deviceName: "",
        isPhysicalDevice: true,
        isDeviceRooted: false,
        isWifiConnected: true,
        username: userName,
        loginType: "form",
        latLong: latitude + "," + longitude,
        appVersion: "",
        createdAt: Date.now(),
        permission: permission ? ["notification"] : [],
      },
    };

    const messageDocRef = db
      .collection(collectionName)
      .doc(challonge_Tournament_Id)
      .collection(challonge_Tournament_Id);
    await messageDocRef.doc(chatId).set(message);
  };

  async function getMessages(data: any) {
    setMessages([]);
    setLoadingNewMessage(true);
    let idArr = [data.assignToId, data.createdById];
    let maxID = Math.max(...idArr);
    let minID = Math.min(...idArr);
    // let challonge_Tournament_Id = minID + "-" + maxID;
    let challonge_Tournament_Id = data.ticketNo;
    console.log({ challonge_Tournament_Id });
    const collectionName = "messages" + environment;
    const messageDocRef: any = db
      .collection(collectionName)
      .doc(challonge_Tournament_Id)
      .collection(challonge_Tournament_Id)
      .orderBy("timeStamp");
    messageDocRef.onSnapshot(messageDocRef, (querySnapshot: any) => {
      let arr: any = [];
      querySnapshot.docs.forEach((doc: any) => {
        arr.push(doc.data());
      });

      setMessages((prvState) => [...arr]);
      setLoadingNewMessage(false);
    });
    // setUnsubscribe(unsub)
  }

  const searchFilterFunction = (text: string) => {
    setsearch(text);
    var refArr = [...ticketsList];
    const newData = refArr.filter((items: any) => {
      const itemData = `${items.ticketNo.toUpperCase()} ${items.ticketDetails.toUpperCase()} ${items.ticketTypeName.toUpperCase()} ${items.createdBy.toUpperCase()}`;
      const textData = text.toUpperCase();

      return itemData.indexOf(textData) > -1;
    });
    setSearchArr([...newData]);
  };
  const filterAll = () => {
    setSelectedTicketTypes({
      answerId: null,
    });
    setsearch("");
    SetTicketsList(TicketsList);
  };
  const filterAssignTome = () => {
    setsearch("Assigned to me");
    let refArr = [...ticketsList];
    let newData = refArr.filter((x: any) => x.assignToId == userId);
    setSearchArr([...newData]);
  };
  const filterClosed = () => {
    setsearch("Closed");
    let refArr = [...ticketsList];
    let newData = refArr.filter((x: any) => x.ticketStatus === "Closed");
    setSearchArr([...newData]);
  };
  const getPlayerIds = async (createdById: string) => {
    console.log({ createdById });
    console.log("getPlayerIds");
    const collectionName = "users" + environment;
    const usersDocRef: any = db.collection(collectionName);
    let doc = await usersDocRef.where("id", "in", [createdById]).get();
    let arr: any = [];
    doc.forEach((data: any) => {
      let playerData = data.data();
      if (playerData.playerId.length) {
        arr.push(playerData.playerId);
      }
    });
    console.log({ arr });
    setPlayerIds(arr);
  };
  const sendNotification = (text: string) => {
    let challonge_Tournament_Id = tickeInfo.ticketNo;

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Basic ${oneSignalRestApiKey}`
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      app_id: "f11b979e-3baa-42f0-968b-0f7538366b4a",
      data: {
        isGroup: true,
        chatId: challonge_Tournament_Id,
        type: "chat",
        groupName: "Grid Customor Service",
      },
      headings: {
        en: "Grid Customor Service",
      },
      contents: {
        en: text,
      },
      include_player_ids: playerIds,
    });

    var requestOptions: any = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://onesignal.com/api/v1/notifications", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  };
  // const url = tickeInfo?.attachmentList.every((x:any) => x.includes("Attachments"));
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Box h="calc(80vh)">
        <MainContainer responsive>
          <Sidebar position="left" scrollable={false}>
            <ConversationHeader
              style={{ backgroundColor: "#F5F5F5", height: "60px" }}
            >
              <ConversationHeader.Content>
                <Flex
                  w="80%"
                  justifyContent={"space-between"}
                  alignItems="center"
                >
                  <Text
                    cursor={"pointer"}
                    onClick={filterAll}
                    fontWeight={"bold"}
                    fontSize={"12px"}
                  >
                    All ({TicketsList?.length})
                  </Text>
                  <Text
                    cursor={"pointer"}
                    onClick={filterAssignTome}
                    fontWeight={"bold"}
                    fontSize={"12px"}
                  >
                    Assigned to me
                  </Text>

                  <Text
                    cursor={"pointer"}
                    onClick={filterClosed}
                    fontWeight={"bold"}
                    fontSize={"12px"}
                  >
                    Closed
                  </Text>
                </Flex>
              </ConversationHeader.Content>
              <ConversationHeader.Actions>
                {/* <InfoButton />
                 */}
                {/* <Menu /> */}
              </ConversationHeader.Actions>
            </ConversationHeader>

            <Search
              value={search}
              onChange={(e: any) => searchFilterFunction(e)}
              onClearClick={() => setsearch("")}
              placeholder="Search..."
            />
            <Flex py="10px" px="10px" gap="10px" flexWrap="wrap">
              {ticketTypesArr.map((data, i) => {
                return (
                  <Button
                    onClick={() => {
                      setSelectedTicketTypes(data);
                      searchFilterFunction(data.answerChoice);
                    }}
                    key={i}
                    fontSize="12px"
                    variant="brand"
                    fontWeight="400"
                    // w="48%"
                    h="30px"
                    px="10px"
                    // py='0px'
                    borderRadius={8}
                    color={
                      selectedticketTypes.answerId === data.answerId
                        ? "white"
                        : "black"
                    }
                    bg={
                      selectedticketTypes.answerId === data.answerId
                        ? buttonColorBrand
                        : buttonColor
                    }
                    _active={{ bg: buttonColorBrand, color: "white" }}
                    _hover={{ bg: buttonColorBrand, color: "white" }}
                    _focus={{ bg: buttonColorBrand, color: "white" }}
                  >
                    {data.answerChoice}
                  </Button>
                );
              })}
            </Flex>
            <ConversationList loading={loading}>
              {(search.length ? searchArr : ticketsList).map(
                (data: any, i: number) => {
                  return (
                    <Conversation
                      active={data.id === tickeInfo?.id}
                      onClick={() => {
                        SetTickeInfoFunc(data);
                        console.log({ data });
                      }}
                      key={i}
                      name={data.createdBy}
                      lastSenderName={data.createdBy}
                      info={data.ticketDetails}
                      style={{ height: 80 }}
                    >
                      <Avatar
                        src={data.createdByProfilePath ?? userAvatar}
                        name="Img"
                      />

                      <Conversation.Operations
                        style={{
                          position: "absolute",
                          bottom: 5,
                          width: "80%",
                          left: "20%",
                          // display:'flex',
                          // justifyContent:'center',
                          // alignItems:'center'
                        }}
                        visible
                      >
                        <Button
                          fontSize="sm"
                          variant="outline"
                          borderColor="#298BE2"
                          color="#084881"
                          bgColor={"#E9F3FC"}
                          fontWeight="light"
                          h="20px"
                          borderRadius={4}
                          mr="20px"
                          //   m={"20px"}
                        >
                          {data.ticketTypeName}
                        </Button>
                      </Conversation.Operations>
                      <Button
                        fontSize="sm"
                        variant="outline"
                        borderColor="gray"
                        color="gray"
                        fontWeight="500"
                        h="30px"
                        w="50px"
                        borderRadius={8}
                        mr="20px"
                        //   m={"20px"}
                      >
                        Mark
                      </Button>
                    </Conversation>
                  );
                }
              )}
            </ConversationList>
          </Sidebar>

          <ChatContainer>
            <ConversationHeader
              style={{ backgroundColor: "#F5F5F5", height: "60px" }}
            >
              <ConversationHeader.Back />
              <ConversationHeader.Content>
                <Flex
                  w="90%"
                  justifyContent={"space-between"}
                  alignItems="center"
                >
                  <HStack w="30%">
                    <Text fontWeight={"bold"} fontSize={"12px"}>
                      Owner:
                    </Text>
                    <FormControl>
                      <Select
                        isDisabled={tickeInfo ? false : true}
                        colorScheme="purple"
                        options={userDropDown}
                        onChange={assignTicket}
                        value={selectedUsers}
                      />
                    </FormControl>
                  </HStack>
                  <Box w="30%">
                    <Text fontWeight={"bold"} fontSize={"12px"}>
                      Ticket Type : {tickeInfo?.ticketTypeName}
                    </Text>
                  </Box>
                  <Box w="30%">
                    <Text fontWeight={"bold"} fontSize={"12px"}>
                      Received :{" "}
                      {tickeInfo?.assignDate
                        ? moment(tickeInfo?.assignDate).format(
                            "MMMM Do, YYYY HH:mm:a"
                          )
                        : "Not assigned"}
                    </Text>
                  </Box>
                </Flex>
              </ConversationHeader.Content>
              <ConversationHeader.Actions>
                <Button
                  disabled={tickeInfo?.ticketStatus === "Closed" ? true : false}
                  onClick={closeTicket}
                  isLoading={closing}
                  loadingText="Closing.."
                  fontSize="sm"
                  variant="outline"
                  borderColor="gray"
                  color="gray"
                  fontWeight="500"
                  h="30px"
                  w="150px"
                  borderRadius={8}
                  mr="20px"
                  //   m={"20px"}
                >
                  {tickeInfo?.ticketStatus === "Closed"
                    ? "Closed"
                    : "Mark Closed"}
                </Button>
                {/* <Menu /> */}
              </ConversationHeader.Actions>
            </ConversationHeader>
            <MessageList
              style={{ backgroundColor: "#FAFAFB" }}
              loading={loadingNewMessage}
              // typingIndicator={<TypingIndicator content="Zoe is typing" />}
            >
              {tickeInfo && (
                <>
                  {" "}
                  <MessageSeparator
                    style={{
                      border: "1px solid #707070",
                      marginTop: 20,
                      borderRadius: 8,
                      padding: 20,
                    }}
                  >
                    <Flex
                      w="100%"
                      justifyContent={"space-between"}
                      alignItems="flex-start"
                    >
                      <Box>
                        <Text
                          textAlign={"left"}
                          fontWeight={"bold"}
                          fontSize={"12px"}
                        >
                          User: {tickeInfo.createdBy}
                        </Text>
                        <Text
                          textAlign={"left"}
                          fontWeight={"bold"}
                          fontSize={"12px"}
                        >
                          User Reported : {tickeInfo.assignTo}
                        </Text>
                        <Text
                          textAlign={"left"}
                          fontWeight={"bold"}
                          fontSize={"12px"}
                        >
                          Ticket Type : {tickeInfo.ticketTypeName}
                        </Text>

                        <Text
                          textAlign={"left"}
                          fontWeight={"bold"}
                          fontSize={"12px"}
                        >
                          Description : {tickeInfo.ticketDetails}
                        </Text>
                      </Box>
                      <Box>
                        <Text fontWeight={"bold"} fontSize={"12px"}>
                          Ticket ID: {tickeInfo.id}
                        </Text>
                        <Text fontWeight={"bold"} fontSize={"12px"}>
                         Attached image: 
                        </Text>
                        { tickeInfo.attachmentList && tickeInfo.attachmentList.length > 0 ? (
                          // <>
                          //   <button onClick={() => setIsModalOpen(true)}>
                          //     <img
                          //       height="50px"
                          //       width="50px"
                          //       alt="Attached"
                          //       src={tickeInfo.attachmentPath}
                          //       className="cursor-pointer rounded-md"
                          //     />
                          //   </button>

                            
                          //     <ImageModal
                          //       isOpen={isModalOpen}
                          //       onClose={() => setIsModalOpen(false)}
                          //       imageUrl={tickeInfo.attachmentPath}
                          //     />
                          // </>
                          <>
                            {/* Thumbnail Images */}
                            <div className="flex space-x-2">
                              {tickeInfo.attachmentList.map((imgUrl:any, index:any) => (
                                <button key={index} onClick={() => {setSelectedImage(imgUrl);setIsModalOpen(true)}}>
                                  <img
                                    style={{marginRight:"8px", height:"50px", width:"50px", objectFit:"cover"}}
                                    height="50px"
                                    width="50px"
                                    alt={`Img ${index + 1}`}
                                    src={imgUrl}
                                    className="cursor-pointer rounded-md"
                                  />
                                </button>
                              ))}
                            </div>

                            {/* Render Modal when an image is clicked */}
                            {selectedImage && (
                              <ImageModal
                                isOpen={isModalOpen}
                                onClose={() => setIsModalOpen(false)}
                                imageUrl={selectedImage}
                              />
                            )}
                          </>
                        ) : (
                          <p className="font-bold text-sm">Not attached</p>
                        )}
                      </Box>
                    </Flex>
                  </MessageSeparator>
                  {tickeInfo?.assignDate && (
                    <MessageSeparator
                      style={{ backgroundColor: "transparent" }}
                      content={moment(tickeInfo?.assignDate).format(
                        "MMMM Do, YYYY; HH:mm:a"
                      )}
                    />
                  )}
                </>
              )}

              {messages.map((data, i) => {
                return (
                  <Message
                    key={i}
                    model={{
                      message: data.content,
                      sentTime: "15 mins ago",
                      sender: "Zoe",
                      direction:
                        data.idFrom == userId ? "outgoing" : "incoming",
                      position: "single",
                    }}
                  >
                    {data.contentType === "ticketNote" && (
                      <Message.Header sender="Notes For Owner" />
                    )}
                    {data.contentType === "ticketNote" && (
                      <Avatar src={adminAvatar} />
                    )}
                    {data.contentType != "ticketNote" &&
                      data.idFrom == userId && (
                        <Avatar src={logo === "" ? userAvatar : logo} />
                      )}
                    {data.contentType != "ticketNote" &&
                      data.idFrom != userId && (
                        <Avatar
                          src={tickeInfo.createdByProfilePath ?? userAvatar}
                        />
                      )}
                  </Message>
                );
              })}
            </MessageList>
            <Box w="100px" zIndex={100} h="50px" bg="red"></Box>

            <MessageInput
              attachButton={false}
              disabled={
                tickeInfo?.ticketStatus === "Closed"
                  ? true
                  : tickeInfo?.assignToId
                  ? false
                  : true
              }
              placeholder="Type message here"
              value={messageInputValue}
              // onChange={(val) => setMessageInputValue(val)}
              onChange={(innerHtml, textContent, innerText, nodes) => {
                // console.log({ innerHtml, textContent, innerText, nodes });
                setMessageInputValue(textContent);
              }}
              onSend={() => initChat(messageInputValue)}
            />
            <InputToolbox
              style={{
                // height: "70px",
                padding: "10px",
                width: "100%",
                backgroundColor: "#949CB9",
              }}
            >
              <Flex w="full" alignItems={"center"}>
                <Text
                  cursor={"pointer"}
                  onClick={() =>
                    tickeInfo.assignToId != userId
                      ? settextType("ticketNote")
                      : settextType("Message")
                  }
                  px="10px"
                  color={textType === "Message" ? "white" : "#D4D7E3"}
                >
                  Message
                </Text>
                <Text
                  cursor={"pointer"}
                  onClick={() => settextType("ticketNote")}
                  px="10px"
                  color={textType === "ticketNote" ? "white" : "#D4D7E3"}
                >
                  Notes
                </Text>
              </Flex>
            </InputToolbox>
          </ChatContainer>
        </MainContainer>
      </Box>
    </Box>
  );
};
export default TicketingSystem;
