import React, { useState, useEffect, Suspense } from "react";
import ReactDOM from "react-dom";
import "./assets/css/App.css";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "./layouts/auth";
import AdminLayout from "./layouts/admin";
import { useDispatch, useSelector } from "react-redux";
import { getGameList } from "redux/slices/Events";
import { AppDispatch } from "redux/store";
import { environment, oneSignalKey } from "utilities/constants";
import { app, db, auth } from "firebaseConfig";
import OneSignal from "react-onesignal";
import { useHistory, useLocation } from "react-router-dom";
import { Flex, Spinner } from "@chakra-ui/react";
import { getRoleWiseMenu } from "redux/slices/AdminsTableLists";
import axios from "axios";
import PublicBrackets from "views/auth/PublicBrackets";

const App = () => {
  const [initialized, setInitialized] = useState(false);
  const { menuList, loadingMenu } = useSelector(
    (state: any) => state.AdminsTableLists
  );

  const dispatch: AppDispatch = useDispatch();
  const { isAuthenticated, userName, userId, logo, role } = useSelector(
    (state: any) => state.Auth
  );
  const updateFirebaseUser = async (
    Id: string,
    name: any,
    image: any,
    PushSubscriptionId: string
  ) => {
    let permission = OneSignal.Notifications.permission;
    console.log({ permission });
    const res = await axios.get("https://api.ipify.org?format=json");
    const ip = res.data.ip;
    const collectionName = "users" + environment;
    let latitude;
    let longitude;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        latitude = position.coords.latitude;
        longitude = position.coords.longitude;
        console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
    const messagesCol: any = db.collection(collectionName).doc(Id);
    try {
      await messagesCol.set({
        id: Id,
        name: name,
        image: image,
        platform: "web",
        lastCallFrom: "",
        lastCallFromName: "",
        isGroupCall: false,
        isUpdateCallStatus: false,
        isVideoCall: false,
        roomId: "",
        onlineStatus: "online",
        callStatus: "idle",
        notificationStatus: permission ? "subscribed" : "unSubscribe",
        playerId: PushSubscriptionId,
        fcmToken: "",
        lastLoginTime: Date.now(),
        appAllowedPermissions: [],
        privacyACL: {
          showProfile: false,
        },
        appLanguage: "en_US",

        basicInfo: {
          platform: "web",
          ip: ip,
          deviceId: "",
          deviceName: "",
          isPhysicalDevice: true,
          isDeviceRooted: false,
          isWifiConnected: true,
          username: name,
          loginType: "form",
          latLong: latitude + "," + longitude,
          appVersion: "",
          createdAt: Date.now(),
          permission: permission ? ["notification"] : [],
        },
      });
    } catch (err: any) {
      console.log({ err });
    }
  };
  const initOneSignal = async () => {
    try {
      OneSignal.init({
        // appId: "f11b979e-3baa-42f0-968b-0f7538366b4a",
        appId: oneSignalKey,
        safari_web_id:
          "web.onesignal.auto.42873e37-42b9-4e5d-9423-af83e9e44ff4",
        welcomeNotification: {
          disable: false,
          title: "Welcome",
          message: "Welocme to Grid Platform",
        },

        notifyButton: {
          enable: true,
        },
      }).then(async () => {
        OneSignal.Slidedown.promptPush();
        // OneSignal.Debug.setLogLevel("trace");
        // OneSignal.login(userId.toString());
        // OneSignal.User.addAlias("currentUser", userId.toString());
        OneSignal.Notifications.setDefaultUrl(
          "https://adminweb.stage.gridgamers.com/"
        );
        OneSignal.Notifications.setDefaultTitle("Powered by Grid!");
        const isSupported = OneSignal.Notifications.isPushSupported();
        let permission = OneSignal.Notifications.permission; // boolean
        OneSignal.Notifications.requestPermission();
        let PushSubscriptionId = OneSignal.User.PushSubscription.id;
        let PushSubscriptionToken = OneSignal.User.PushSubscription.token;

        updateFirebaseUser(
          userId.toString(),
          userName,
          logo,
          PushSubscriptionId
        );
        console.log({
          isSupported,
          permission,
          PushSubscriptionId,
          PushSubscriptionToken,
        });

        // OneSignal.User.addAlias("myAlias", "1");
      });
    } catch (e) {
      console.log({ e });
    }
  };

  const testAxiosOtherServer = () => {
    // var axios = require('axios');

    var config = {
      method: "get",
      url: "https://cmd-api-dev.azurewebsites.net/api/cmdsched/getUserInfo",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Accept-Language": "en-US,en;q=0.9",
        Connection: "keep-alive",
        "Content-Type": "application/x-www-form-urlencoded",
        DNT: "1",
        Origin: "https://commandscheduling-dev.azurewebsites.net",
        Referer: "https://commandscheduling-dev.azurewebsites.net/",
        "Sec-Fetch-Dest": "empty",
        "Sec-Fetch-Mode": "cors",
        "Sec-Fetch-Site": "cross-site",
        "User-Agent":
          "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Safari/537.36",
        "sec-ch-ua":
          '"Not_A Brand";v="8", "Chromium";v="120", "Google Chrome";v="120"',
        "sec-ch-ua-mobile": "?0",
        "sec-ch-ua-platform": '"Windows"',
        Cookie:
          "ARRAffinity=0f71fcc4a6d73e1699f6d75f505a8aa097b9d235aae32fb82e2090360ea73b73; ARRAffinitySameSite=0f71fcc4a6d73e1699f6d75f505a8aa097b9d235aae32fb82e2090360ea73b73",
      },
    };

    axios(config)
      .then(function (response: any) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error: any) {
        console.log({ error });
      });
  };

  useEffect(() => {
    // testAxiosOtherServer();
    let curretFirebaseUser = auth.currentUser;
    console.log({ curretFirebaseUser });
    if (isAuthenticated) {
      let payload = { RoleId: role };
      dispatch(getRoleWiseMenu(payload));
      auth
        .signInAnonymously()
        .then((res) => {
          // Signed in..
          let curretFirebaseUser = auth.currentUser;
          console.log({ curretFirebaseUser });
          console.log("Signed in success", { res });
          updateFirebaseUser(userId.toString(), userName, logo, "");
          initOneSignal();
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          // ...
        });

      dispatch(getGameList());
    }
  }, [isAuthenticated]);
  useEffect(() => {
    document.title = "Grid";

    if (role) {
      if (role != 1) {
        document.title = "Grid | Hub ";
      } else {
        document.title = "Grid | Admin";
      }

      let payload = { RoleId: role };
      dispatch(getRoleWiseMenu(payload));
    }
  }, [role]);

  useEffect(() => {
    console.log({ loadingMenu });
  }, [loadingMenu]);

  if (loadingMenu === true) {
    return (
      <Flex
        w={"full"}
        justifyContent="center"
        alignItems={"center"}
        h="100vh"
        bg="linear-gradient(205.41deg, #1E75FF 0%, #19BAEE 100%)"
      >
        <Spinner size={"xl"} color="white" />.
      </Flex>
    );
  }
  return (
    <HashRouter>
      <Switch>
        {isAuthenticated ? (
          <Route path={`/admin`} component={AdminLayout} />
        ) : (
          <Route path={`/auth`} component={AuthLayout} />
        )}
        <Route path={`/brackets`} component={PublicBrackets} />
        {isAuthenticated && role != 1 ? (
          <Redirect from="/" to="/admin/Profile" />
        ) : isAuthenticated && role == 1 ? (
          <Redirect from="/" to="/admin/data-tables" />
        ) : (
          <Redirect from="/" to="/auth" />
        )}
      </Switch>
    </HashRouter>
  );
};
export default App;
